// overriding bs variables;

$body-back: #F1F5F9;
$dark: #016997;
$box-colour: #dce9f4;

$font-size-base: 1rem; // unchanged
$h1-font-size: $font-size-base * 1.3;

// include bs variables here so they can be used in the theme.
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
