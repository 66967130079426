// css classes which aren't linked to a particular part of the system but rather
// can be reused anywhere
@import 'variables';

.box {
  border-radius: $border-radius;
  background: $box-colour;
  padding: 1rem;
  min-height: 200px;
}
