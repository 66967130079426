@import 'variables';

body {
  padding-top: 4rem;
  background-color: $body-back;
  overflow-y: hidden;
}

$item-size: 300px;
.dropzone {
  min-height: 200px;
  .previews {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .file-preview {
    padding: 5px;
    width: $item-size;
    font-size: 13px;
    img {
      width: $item-size;
      max-height: $item-size;
      object-fit: cover;
    }
  }
}

#sheet {
  height: 200px;
  min-width: 100%;
}

$invalid-colour: lightpink;
$exists-colour: lighten(theme-color('info'), 20%);

.ag-header-viewport {
  .custom-select {
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.75rem;
  }
  .invalid .custom-select {
    background: $invalid-colour;
    color: black;
  }
}

.ag-theme-balham {
  .remove-cell {
    padding: 0;
    border: none !important;
    text-align: center;
    .btn {
      padding: 0 0.25rem;
    }
  }
  .ag-row .invalid {
    background: $invalid-colour !important;
  }
  .ag-cell {
    padding-right: 5px;
    padding-left: 5px;
  }
  .ag-cell-inline-editing {
    background-color: transparent;
    padding: 0;
  }
  .user_exists {
    background: $exists-colour;
  }
  .readonly {
    background: $gray-500;
    color: $gray-700;
  }
  .ag-header-cell {
    padding-left: 2px;
    padding-right: 3px;
    line-height: 25px;
    &::after {
      height: 40px;
    }
  }
  .custom-select {
    border-radius: 0;
    padding: 3px;
  }
  .remove-column {
    background: lightpink;
  }
}

.sheet-summary {
  .error {
    color: $form-feedback-invalid-color;
  }
  .ok {
    color: $form-feedback-valid-color;
  }
  .errors {
    padding: 10px;
    border: 1px red solid;
    background: rgba(255, 182, 193, 0.59);
    border-radius: 4px;
  }
  .success {
    padding: 10px;
  }
}

.custom-cell-select {
  @extend .custom-select;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 0.75rem;
  border-radius: 0;
}



.role-button {
  p {
    font-size: 14px;
    max-width: 230px;
    margin: 15px auto;
    text-align: justify;
  }
  h3 {
    font-size: 1.65rem;
  }
  width: 33%;
}

.title {
  margin: 0 auto;
  h1 {
    margin-bottom: 0;
  }
}

.remove-column-label {
  color: $danger;
  font-size: 11px;
  padding: 3px;
  font-weight: 500;
  &:hover {
    text-decoration: none;
  }
}
